import * as React from "react";
import * as classNames from "classnames";

import { Dropdown, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink } from "reactstrap";
import { Locale, WithId } from "@maxxton/cms-api";
import { SiteRedirectData, WidgetOptions } from "./";
import { getI18nLocaleString, wrapProps } from "../../../i18n";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { PageWidgetBaseProps } from "../pageWidget.types";
import { SmartLink } from "../../../components/SmartLink";
import { State } from "../../../redux";
import { UrlParamsUtil } from "../../../utils/urlparam.util";
import { connect } from "react-redux";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { isEqual } from "../../../components/utils";
import namespacesList from "../../../i18n/namespaceList";
import { parse } from "query-string";

export interface LanguagePropsBase extends PageWidgetBaseProps<WidgetOptions> {
    context: CMSProvidedProperties;
    locales: Array<Locale & WithId>;
    data: SiteRedirectData[];
    className?: string;
    currentSelection: SiteRedirectData | null;
}

interface LanguageStoreProps {
    dynamicFilter: DynamicFilter;
}

interface LanguageProps extends CMSProvidedProperties, LanguageStoreProps, LanguagePropsBase {}

interface LanguageState {
    dropdownOpen: boolean;
    currentSelection: SiteRedirectData | null;
    langModalPopup: boolean;
    siteData: SiteRedirectData[];
}

class LanguageInputBase extends React.PureComponent<LanguageProps, LanguageState, LanguageStoreProps> {
    constructor(props: LanguageProps) {
        super(props);
        this.state = {
            dropdownOpen: false,
            currentSelection: props.currentSelection,
            langModalPopup: false,
            siteData: props.data,
        };
    }
    public componentDidMount() {
        const { options, dynamicFilter } = this.props;
        if (options.enableSearchParams) {
            this.updateDynamicParams(dynamicFilter);
        }
    }
    public componentDidUpdate(prevProps: LanguageProps, prevState: LanguageState) {
        const { options, dynamicFilter } = this.props;
        if (!isEqual(this.state.currentSelection, prevState.currentSelection)) {
            this.updateCurrentSelection();
        }
        if (!isEqual(dynamicFilter, prevProps.dynamicFilter) && options.enableSearchParams) {
            this.updateDynamicParams(dynamicFilter);
        }
    }

    private updateDynamicParams = (dynamicFilter: DynamicFilter) => {
        const { siteData } = this.state;
        const dynamicFilterParams = UrlParamsUtil.getUrlParamsFromFilter(dynamicFilter);
        const updatedSiteData = siteData.map((site) => {
            const link = site.sitePageUrl!.split("?")[0];
            const existingParams = parse(site.sitePageUrl!.split("?")[1]);
            return { ...site, sitePageUrl: UrlParamsUtil.getBmUrlWithQueryParams(link, { ...existingParams, ...dynamicFilterParams }) };
        });
        this.setState({ siteData: updatedSiteData });
    };

    public render(): JSX.Element | null {
        const {
            options: { viewOption, styleOption, highlightActive },
        } = this.props;
        const { currentSelection, siteData } = this.state;

        if (styleOption === "Show as a popup") {
            return this.renderLangPopup();
        }

        if (styleOption === "Show as a modal popup") {
            return (
                <div className="generic-form-tabbed lang-modal" onClick={this.handleLangInfoModal}>
                    <a className="lang-modal-link">
                        <div>
                            {viewOption === "langName" && currentSelection?.displayName && <div className="d-inline-block">{currentSelection.displayName}</div>}
                            {viewOption === "flag" && <div className={`modal-flag d-inline-block locale-${currentSelection?.localeName}`} />}
                            {viewOption === "flagWithLangName" && currentSelection?.localeName && (
                                <div
                                    // eslint-disable-next-line max-len
                                    className={`dropdown-flagWithLangName d-inline-block locale-${currentSelection.localeName}`}
                                >
                                    <span>{currentSelection.displayName}</span>
                                </div>
                            )}
                            {viewOption === "flagWithInitials" && currentSelection?.localeName && (
                                <div
                                    // eslint-disable-next-line max-len
                                    className={`dropdown-flagWithInitials d-inline-block locale-${currentSelection.localeName}`}
                                >
                                    <span>{currentSelection.localeCode && currentSelection.localeCode.toUpperCase()}</span>
                                </div>
                            )}
                        </div>
                        {this.renderModal()}
                    </a>
                </div>
            );
        }

        return (
            <div className="generic-form-tabbed lang-inline">
                <Nav>
                    {siteData.map((tab, ind) => (
                        <NavItem key={ind}>
                            {viewOption === "langName" && tab.displayName && (
                                <NavLink
                                    tag={SmartLink}
                                    target="_self"
                                    className={classNames(`locale-${tab.localeName}`, { "lang-active": this.checkSelectedLang(tab.displayName) && highlightActive })}
                                    href={tab.sitePageUrl}
                                >
                                    {tab.displayName}
                                </NavLink>
                            )}
                            {viewOption === "flag" && (
                                <NavLink
                                    tag={SmartLink}
                                    target="_self"
                                    className={classNames(`locale-${tab.localeName}`, { "lang-active": this.checkSelectedLang(tab.displayName) && highlightActive })}
                                    href={tab.sitePageUrl}
                                />
                            )}
                            {viewOption === "flagWithLangName" && tab.displayName && (
                                <NavLink
                                    tag={SmartLink}
                                    target="_self"
                                    className={classNames(`locale-${tab.localeName}`, { "lang-active": this.checkSelectedLang(tab.displayName) && highlightActive })}
                                    href={tab.sitePageUrl}
                                >
                                    {" "}
                                    {tab.displayName}
                                </NavLink>
                            )}
                            {viewOption === "flagWithInitials" && tab.localeName && (
                                <NavLink
                                    tag={SmartLink}
                                    target="_self"
                                    className={classNames(
                                        `locale-${tab.localeName}`,
                                        { "lang-active": this.checkSelectedLang(tab.displayName) && highlightActive },
                                        "dropdown-flagWithInitials d-inline-block"
                                    )}
                                    href={tab.sitePageUrl}
                                >
                                    {" "}
                                    <span>{tab.localeCode && tab.localeCode.toUpperCase()}</span>
                                </NavLink>
                            )}
                        </NavItem>
                    ))}
                </Nav>
            </div>
        );
    }

    private checkSelectedLang = (displayName?: string): boolean => this.state.currentSelection?.displayName === displayName;

    private renderLangPopup = (): JSX.Element | null => {
        const {
            options: { viewOption, dropdownOptions },
            className,
        } = this.props;
        const { currentSelection, siteData } = this.state;

        if (currentSelection) {
            return (
                <div className="generic-form-tabbed lang-popup">
                    <Dropdown nav className={`${dropdownOptions === "Hover" ? "hover-type" : ""} lang-dropdown-link`} isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                        <DropdownToggle nav caret>
                            {viewOption === "langName" && currentSelection.displayName && <div className="d-inline-block">{currentSelection.displayName}</div>}
                            {viewOption === "flag" && <div className={`dropdown-flag d-inline-block locale-${currentSelection.localeName}`} />}
                            {viewOption === "flagWithLangName" && currentSelection.localeName && (
                                <div
                                    // eslint-disable-next-line max-len
                                    className={`dropdown-flagWithLangName d-inline-block locale-${currentSelection.localeName}`}
                                >
                                    <span>{currentSelection.displayName}</span>
                                </div>
                            )}
                            {viewOption === "flagWithInitials" && currentSelection.localeName && (
                                <div
                                    // eslint-disable-next-line max-len
                                    className={`dropdown-flagWithInitials d-inline-block locale-${currentSelection.localeName}`}
                                >
                                    <span>{currentSelection.localeCode && currentSelection.localeCode.toUpperCase()}</span>
                                </div>
                            )}
                        </DropdownToggle>
                        <DropdownMenu className={viewOption === "flag" ? `menu-dropdown-popup lang-popup ${className}` : "menu-dropdown-popup lang-popup"}>
                            <div className="lang-flag-wrap">
                                {siteData
                                    .filter((tab) => tab.localeName)
                                    .map((tab, ind) => (
                                        <div className={classNames("lang-option-wrap", tab.localeName, { ["selected"]: this.state.currentSelection?.displayName === tab.displayName })} key={ind}>
                                            {viewOption === "langName" && tab.displayName && (
                                                <NavLink tag={SmartLink} className="lang-name" target="_self" href={tab.sitePageUrl}>
                                                    {tab.displayName}
                                                </NavLink>
                                            )}
                                            {viewOption === "flag" && <NavLink tag={SmartLink} target="_self" className={`flag locale-${tab.localeName}`} href={tab.sitePageUrl} />}
                                            {viewOption === "flagWithLangName" && tab.localeName && (
                                                <NavLink tag={SmartLink} target="_self" className={`flag-with-lang-name locale-${tab.displayName}`} href={tab.sitePageUrl}>
                                                    <span>{tab.displayName}</span>
                                                </NavLink>
                                            )}
                                            {viewOption === "flagWithInitials" && tab.localeName && (
                                                <NavLink tag={SmartLink} target="_self" className={`flag-with-lang-initials locale-${tab.localeName}`} href={tab.sitePageUrl}>
                                                    <span>{tab.localeCode && tab.localeCode.toUpperCase()}</span>
                                                </NavLink>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            );
        }

        return null;
    };

    private handleLangInfoModal = () => {
        this.setState({ langModalPopup: !this.state.langModalPopup });
    };

    private renderModal = () => {
        const {
            options: { viewOption },
        } = this.props;
        const { currentSelection, langModalPopup, siteData } = this.state;
        const { currentLocale, site } = this.props.context;

        return (
            <Modal isOpen={langModalPopup} toggle={this.handleLangInfoModal} size="md" className={"modal-language"}>
                <ModalHeader tag="h4" toggle={this.handleLangInfoModal} className="no-background">
                    {getI18nLocaleString(namespacesList.widgetLanguage, "languageModal", currentLocale, site)}
                </ModalHeader>
                <ModalBody>
                    <div className="menu-modal-popup">
                        {siteData
                            .filter((tab, ind) => tab.localeName !== currentSelection?.localeName)
                            .map((tab, ind) => (
                                <div className={`lang-option-wrap ${tab.localeName} ${viewOption === "flag" ? "lang-option-flag" : ""} `} key={ind}>
                                    {viewOption === "langName" && tab.displayName && (
                                        <NavLink tag={SmartLink} className="lang-name flag-wrap" target="_self" href={tab.sitePageUrl}>
                                            {tab.displayName}
                                        </NavLink>
                                    )}
                                    {viewOption === "flag" && <NavLink tag={SmartLink} target="_self" className={`flag flag-wrap locale-${tab.localeName}`} href={tab.sitePageUrl} />}
                                    {viewOption === "flagWithLangName" && tab.localeName && (
                                        <NavLink tag={SmartLink} target="_self" className={`flag-with-lang-name flag-wrap locale-${tab.displayName}`} href={tab.sitePageUrl}>
                                            <span>{tab.displayName}</span>
                                        </NavLink>
                                    )}
                                    {viewOption === "flagWithInitials" && tab.localeName && (
                                        <NavLink tag={SmartLink} target="_self" className={`flag-with-lang-initials flag-wrap locale-${tab.localeName}`} href={tab.sitePageUrl}>
                                            <span>{tab.localeCode && tab.localeCode.toUpperCase()}</span>
                                        </NavLink>
                                    )}
                                </div>
                            ))}
                    </div>
                </ModalBody>
            </Modal>
        );
    };

    private toggle = () => {
        if (this.props.options.dropdownOptions === "Toggle") {
            this.setState((state) => ({
                ...state,
                dropdownOpen: !state.dropdownOpen,
            }));
        }
    };

    private updateCurrentSelection = (): void => {
        const {
            context: { currentLocale, site },
            data,
        } = this.props;
        const currentSelection: SiteRedirectData | null = getLocalizedContent({ site, currentLocale, localizedContent: data });
        this.setState((state) => ({ currentSelection }));
    };
}

function mapStateToProps(state: State): LanguageStoreProps {
    return {
        dynamicFilter: state.dynamicFilter,
    };
}

const LanguageInputBaseConnect = connect<LanguageStoreProps>(mapStateToProps)(LanguageInputBase);

export const LanguageInput = wrapProps<LanguagePropsBase>(LanguageInputBaseConnect);
