import * as React from "react";

import { FormSpec, multiSelectStylePicker } from "../../../form-specs";
import { Locale, LocalizedContentBase, LocalizedName, MultiSelectOptionStringValue, Site, WithId } from "@maxxton/cms-api";
import { LocalizedFriendlyUrl, LocalizedPageLink, SitemapPageLinkWidgetOptions } from "../../sitemap/sitemap.types";
import { PageWidgetSpec, Widget } from "../../";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { LanguageInput } from "./Language";
import { UrlParamsUtil } from "../../../utils/urlparam.util";
import { WidgetGroup } from "../../widget.enum";
import { allSites } from "../../../components/utils";
import { findLocalizedPageLink } from "../../sitemap/sitemap.util";
import { findMultiSelectStyleClassNames } from "../../../themes";
import { getI18nLocaleObject } from "../../../i18n";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { getSitesInSiteGroup } from "../../../utils/site.util";
import namespaceList from "../../../i18n/namespaceList";
import { pageLink } from "../../../routing";

const TARGETS = ["language"];

type ViewOption = "langName" | "flag" | "flagWithLangName" | "flagWithInitials";
type styleOption = "Show inline" | "Show as a popup" | "Show as a modal popup";
export interface WidgetOptions {
    viewOption: string;
    styleOption: string;
    dropdownOptions: string;
    styleIds: MultiSelectOptionStringValue[];
    enableSearchParams?: boolean;
    highlightActive?: boolean;
}

export interface SiteRedirectData extends LocalizedContentBase {
    displayName?: string;
    localeName?: string;
    sitePageUrl?: string;
    localeCode?: string;
}

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "language-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetLanguage, "languageWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.widgetLanguage, "languageWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.widgetFlexbox, "general"),
                    properties: [
                        [
                            {
                                variable: "viewOption",
                                label: getI18nLocaleObject(namespaceList.widgetLanguage, "viewOption"),
                                type: "select",
                                default: "flagWithLangName" as ViewOption,
                                optionList: [
                                    {
                                        value: "langName",
                                        label: getI18nLocaleObject(namespaceList.widgetLanguage, "name"),
                                    },
                                    {
                                        value: "flag",
                                        label: getI18nLocaleObject(namespaceList.widgetLanguage, "flag"),
                                    },
                                    {
                                        value: "flagWithLangName",
                                        label: getI18nLocaleObject(namespaceList.widgetLanguage, "flagName"),
                                    },
                                    {
                                        value: "flagWithInitials",
                                        label: getI18nLocaleObject(namespaceList.widgetLanguage, "flagInitials"),
                                    },
                                ],
                            },
                            {
                                variable: "styleOption",
                                label: getI18nLocaleObject(namespaceList.widgetLanguage, "styleOption"),
                                type: "select",
                                default: "Show inline" as styleOption,
                                optionList: [
                                    {
                                        value: "Show inline",
                                        label: getI18nLocaleObject(namespaceList.widgetLanguage, "styleOptionInline"),
                                    },
                                    {
                                        value: "Show as a popup",
                                        label: getI18nLocaleObject(namespaceList.widgetLanguage, "styleOptionPopup"),
                                    },
                                    {
                                        value: "Show as a modal popup",
                                        label: getI18nLocaleObject(namespaceList.widgetLanguage, "styleOptionModalPopup"),
                                    },
                                ],
                            },
                            {
                                variable: "dropdownOptions",
                                label: getI18nLocaleObject(namespaceList.widgetLanguage, "dropdownOptions"),
                                type: "select",
                                default: "Show inline" as styleOption,
                                visible: (options: WidgetOptions) => options.styleOption === "Show as a popup",
                                optionList: [
                                    {
                                        value: "Hover",
                                        label: getI18nLocaleObject(namespaceList.widgetLanguage, "dropdownOptionHover"),
                                    },
                                    {
                                        value: "Toggle",
                                        label: getI18nLocaleObject(namespaceList.widgetLanguage, "dropdownOptionToggle"),
                                    },
                                ],
                            },
                            {
                                variable: "enableSearchParams",
                                label: getI18nLocaleObject(namespaceList.widgetLanguage, "enableSearchParams"),
                                default: false,
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "style"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            {
                                variable: "highlightActive",
                                label: getI18nLocaleObject(namespaceList.widgetLanguage, "highlightActive"),
                                type: "checkbox",
                            },
                        ],
                    ],
                },
            ],
        },
    ],
};

interface GenerateTempDataParams {
    context: CMSProvidedProperties;
    locale: Locale & WithId;
    site?: Site & WithId;
    sites: Array<Site & WithId>;
    localizedPageLink?: LocalizedPageLink;
    enableSearchParams?: boolean;
}

const generateTempData = async (params: GenerateTempDataParams): Promise<SiteRedirectData[]> => {
    const { context, locale, site, sites, localizedPageLink, enableSearchParams } = params;
    const tempdata: SiteRedirectData[] = [];
    let sitePageUrl = "";
    if (sites?.length && localizedPageLink) {
        if (localizedPageLink.useAsExternal) {
            sitePageUrl = localizedPageLink.url;
        } else {
            sitePageUrl = sitePageUrl.concat("//");
            const site = sites.find((site) => site._id === localizedPageLink.siteId);
            if (site) {
                sitePageUrl = sitePageUrl.concat(site.host);
                const url = await pageLink({ site, pageId: localizedPageLink.pageId, context });
                sitePageUrl = sitePageUrl.concat(url ? url : "");
            }
        }
    }

    let displayName: LocalizedName | undefined;
    if (locale.localizedName) {
        displayName = locale.localizedName.find((lName) => lName.locale === context.currentLocale.locale);
    }

    if (displayName?.name) {
        tempdata.push({
            locale: locale?._id || context.currentLocale.locale,
            sitePageUrl: sitePageUrl?.length ? sitePageUrl : "//" + (site?.host || context.site.host),
            displayName: displayName?.name,
            localeName: locale.name,
            localeCode: locale?.code || context.currentLocale.code,
        });
    } else {
        tempdata.push({
            locale: locale?._id || context.currentLocale.locale,
            sitePageUrl: sitePageUrl?.length ? sitePageUrl : "//" + (site?.host || context.site.host),
            displayName: locale?.name || context.currentLocale.name,
            localeName: locale?.name || context.currentLocale.name,
            localeCode: locale?.code || context.currentLocale.code,
        });
    }

    return tempdata;
};

export const languageWidget: PageWidgetSpec<WidgetOptions> = {
    id: "language",
    type: "page",
    widgetGroup: WidgetGroup.CONTENT,
    name: getI18nLocaleObject(namespaceList.widgetLanguage, "languageWidget"),
    description: getI18nLocaleObject(namespaceList.widgetLanguage, "languageWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        viewOption: "flagWithLangName" as ViewOption,
        styleOption: "Show inline" as styleOption,
        dropdownOptions: "Hover",
        styleIds: [],
        enableSearchParams: false,
        highlightActive: false,
    }),
    container: false,
    // eslint-disable-next-line max-len
    async render(widget: Widget<WidgetOptions>, context: CMSProvidedProperties, sitemapPageWidgetOptions?: SitemapPageLinkWidgetOptions) {
        const { site, currentLocale } = context;
        let locales: Array<Locale & WithId> = await context.cmsApi.localeApi.find();
        const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds);
        const tempdata: SiteRedirectData[] = [];
        let sites: Array<Site & WithId>;
        const enableSearchParams = widget.options?.enableSearchParams;

        if (allSites.length) {
            sites = allSites;
        } else {
            sites = await context.cmsApi.siteApi.find({ projection: { sitemap: 0 } });
        }

        // Determine whether the site belongs to a predefined group of websites
        const sitesInCurrentSiteGroup: Array<Site & WithId> | null = await getSitesInSiteGroup({ site, cmsApi: context.cmsApi });

        // If multilanguage feature disabled then use multisite feature by default
        if (!context.site.enableMultiLanguage) {
            if (sitesInCurrentSiteGroup) {
                for (const site of sitesInCurrentSiteGroup) {
                    const localizedPageLink: LocalizedPageLink | undefined = findLocalizedPageLink({ localizedPageLinks: sitemapPageWidgetOptions?.localized, locale: site.locale });
                    const generatedTempData = await generateTempData({ context, locale: site.locale, site, sites, localizedPageLink, enableSearchParams });
                    tempdata.push(...generatedTempData);
                }
            } else {
                for (const locale of locales) {
                    const localizedPageLink: LocalizedPageLink | undefined = findLocalizedPageLink({ localizedPageLinks: sitemapPageWidgetOptions?.localized, locale });
                    const generatedTempData = await generateTempData({ context, locale, sites, localizedPageLink, enableSearchParams });
                    tempdata.push(...generatedTempData);
                }
            }
        } else {
            if (context.site.localeMultiSelect?.length) {
                // Filter out valid locales from all Locales
                const localeIds = context.site.localeMultiSelect.map((al) => al.value);
                locales = locales.filter((locale) => localeIds.indexOf(locale._id) > -1);
            }
            for (const locale of locales) {
                const url = await getUrl(context, sitemapPageWidgetOptions, locale);
                let displayName: LocalizedName | undefined;
                if (locale.localizedName) {
                    displayName = locale.localizedName.find((lName) => lName.locale === context.currentLocale.locale);
                }
                if (displayName?.name) {
                    tempdata.push({ locale: locale._id, sitePageUrl: url, localeName: locale.name, displayName: displayName.name, localeCode: locale.code });
                } else {
                    tempdata.push({ locale: locale._id, sitePageUrl: url, localeName: locale.name, displayName: locale.name, localeCode: locale.code });
                }
            }
        }
        const currentSelection = getLocalizedContent({ site, currentLocale, localizedContent: tempdata });
        return <LanguageInput context={context} options={widget.options} className={styleClasses} locales={locales} data={tempdata} currentSelection={currentSelection} />;
    },
};

async function getUrl(context: CMSProvidedProperties, sitemapPageLinkOptions?: SitemapPageLinkWidgetOptions, locale?: Locale & WithId): Promise<string> {
    let url = "";
    if (sitemapPageLinkOptions) {
        let friendlyUrl: string = sitemapPageLinkOptions.friendlyUrl;
        const isLocalized: boolean =
            !!sitemapPageLinkOptions.localizedFriendlyUrl?.length &&
            sitemapPageLinkOptions.localizedFriendlyUrl.every(
                (localizedFriendlyUrl: LocalizedFriendlyUrl) => localizedFriendlyUrl.friendlyUrl !== "" || localizedFriendlyUrl.friendlyUrl !== undefined
            );
        if (isLocalized) {
            const localizedFriendlyUrl = sitemapPageLinkOptions.localizedFriendlyUrl?.find((lf: LocalizedFriendlyUrl) => lf.locale === locale!._id);
            friendlyUrl = localizedFriendlyUrl?.friendlyUrl || "";
        }
        url = url.concat("//");
        url = url.concat(context.site.host);
        url = url.concat(
            `${locale && (!isLocalized || sitemapPageLinkOptions.home) && locale.code !== context.site.locale.code ? (url.endsWith("/") || url.endsWith("\\") ? locale.code : `/${locale.code}`) : ""}`
        );
        url = url.concat(sitemapPageLinkOptions.home ? "" : friendlyUrl);
    }
    return url?.length ? UrlParamsUtil.correctTrailingSlash(url, context.site) : "";
}
