import { Locale, WithId } from "@maxxton/cms-api";

import { LocalizedPageLink } from "./sitemap.types";

// Checks whether a page link exists for a given locale, with verification for both external and internal links
export const findLocalizedPageLink = ({ localizedPageLinks, locale }: { localizedPageLinks?: LocalizedPageLink[]; locale: Locale & WithId }): LocalizedPageLink | undefined =>
    localizedPageLinks?.find((pageLink: LocalizedPageLink) => {
        if (pageLink.locale !== locale._id) {
            return false;
        }

        if (pageLink.useAsExternal && !pageLink.url) {
            return false;
        }

        if (!pageLink.useAsExternal && !(pageLink.siteId && pageLink.pageId)) {
            return false;
        }

        return true;
    });
